.add-user-box {
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    padding: 16px;
    form{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 24px;
        min-height: 100%;
        label {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: #737373;
        }
        .input-with-button {
            display: flex;
            gap: 8px;
            justify-content: space-between;
            button {
              border: 1px solid #d9d9d9;
              background-color: transparent;
              min-width: max-content;
              border-radius: 4px;
              font-size: 14px;
              color: #737373;
              &:disabled {
                background-color: #e9ecef;
              }
            }
            button.use-unit-opt-btn {
              min-width: 40px;
            }
          }
        .full-width {
            grid-column: span 3;
        }
        button{
            min-width: 108px;
        }
        .item-checkbox-content {
            height: 100%;
            display: flex;
            width: 100%;
            align-items: center;
            padding-top: 30px;
        }
        .npi-field {
            .invalid-feedback {
                display: block;
            }
        }
        .password-field-box{
            position: relative;
            input{
                background-image:none !important;
            }
            i {
                    position: absolute;
                    right: 10px;
                    top: 38px;
                    cursor: pointer;
                }
            }
    }
}

div#npi-search-modal {
    min-width: 900px;
    .npi-search-box {
        display: grid;
        grid-template-columns: repeat(5, 20%);
        gap: 16px;
        margin-bottom: 20px;
        label{
            display: block;
        }
        .npi-search-btn{
            border: 1px solid #d9d9d9;
            background-color: transparent;
            min-width: 70px;
            height: 40px;
            border-radius: 4px;
            font-size: 14px;
            color: #737373;
            box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.07);
            &:hover{
                color: #044D6E;
                font-weight: 600;
            }
        }
    }
    .npi-search-result-table-wrapper{
        max-height: 280px;
        overflow-y: auto;
    }
    .footer-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 24px 24px;

        .note-section {
            display: flex;
            flex-direction: column;

            .notice {
                font-size: 12px;
                color: #B0B0B0;
            }
        }

        span.no-records-fount {
            font-size: 14px;
            font-weight: 600;
        }
    }
}
.--bs-table-active-bg {
    background-color: lightblue; /* or any other color */
  }
  tr.active td {
    background-color: #f4f9fd;
  }