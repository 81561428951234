@import "../../styles/variables";

.faqs-wrapper {
    .faq-list-wrapper {
        margin-top: 32px;

        mark {
            padding: 0;
            color: black;
            font-weight: 500;
            border-radius: 1px;
            background-color: #fbe725;
        }

        .no-results {
            text-align: center;
            font-weight: 500;
            margin-bottom: 0;
            font-size: 14px;
            line-height: normal;
            padding: 0 30px;
        }

        .faq-section {
            margin-bottom: 24px;

            .faq-section-title {
                display: flex;
                align-items: center;
                gap: 12px;
                margin-bottom: 16px;

                h4 {
                    margin: 0;
                    font-size: 16px;
                    line-height: normal;
                }
            }

            .accordion-item {
                font-size: 14px;
                line-height: normal;

                .accordion-button {
                    font-weight: 500;
                    color: $black-850;
                    background-color: rgb(250, 250, 250);

                    &:focus {
                        box-shadow: none;
                    }
                }

                .accordion-body {
                    color: $black-850;

                    img {
                        width: 100%;
                        height: auto;
                        margin-top: 10px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}