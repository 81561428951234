.not-found-wrapper {
    display: flex;
    gap: 48px;
    padding: 48px;
    height: 100dvh;
    background-color: white;
    @media screen and (min-width: 1440px) {
        gap: 64px;
    }

    .flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }

    .image-wrapper {
        img {
            max-width: 600px;
            width: 100%;
            height: auto;
            object-fit: contain;
            @media screen and (min-width: 1920px) {
                max-width: 800px;
            }
        }

        @media screen and (min-width: 1440px) {
            justify-content: flex-end;
        }
    }

    .page-content-wrapper {
        @media screen and (min-width: 1440px) {
            justify-content: flex-start;
        }

        .page-content {

            h1,
            h2 {
                font-weight: bold;
            }

            @media screen and (min-width: 1920px) {
                h1 {
                    font-size: 4rem;
                }

                h2 {
                    font-size: 3.6rem;
                }

                p {
                    font-size: 2rem;
                }

                button {
                    font-size: 1.6rem;
                }
            }
        }
    }

}