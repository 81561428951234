.import-error-section {
    margin: 16px 0;

    h3 {
        font-size: 16px;
        line-height: normal;
        margin: 0;
        margin-bottom: 10px;
        color: #ff0000;
        font-weight: 500;
    }

    .error-tbl {
        th,td {
            font-size: 12px !important;
            line-height: normal !important;
            text-align: left !important;
        }

        .error-info {
            color: #ff0000 !important;
        }
    }
    p {
        font-size: 14px;
        line-height: normal;
        color:red;
    }
}