@import "../../../styles/variables";

.expiring-inventory-report-wrapper {
    padding: 20px;

    .report-content {
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;

        &.success-txt {
            color: $success-color;
        }

        &.error-txt {
            color: $error-color;
        }
    }
}