@import "../../styles/variables";
@import "../../styles/global.scss";

.common-header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;

    .page-title-main {
        display: flex;
        flex-direction: column;
        flex: 1;

        h2 {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            color: $black-850;
            margin: 0;
        }

        p {
            margin: 0;
            font-size: 12px;
            line-height: 16px;
            color: #A6A6A6;
        }
    }

    .search-bar-main {
        flex: 1;

        .search-form {
            display: flex;
            flex-wrap: nowrap;
            gap: 10px;

            input {
                min-width: 250px;
                padding: 8px 10px !important;
                min-height: 35px;
            }
        }
    }

    .header-actions {
        flex: 1;
        text-align: right;
    }
}