@import "../../styles/variables";

.emr-emulator-main {
    font-size: 14px;
    line-height: normal;

    .header-main {
        margin-bottom: 30px !important;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;

        h3 {
            margin: 0;
            padding: 0;
        }

        .actions-wrapper {
            display: flex;
            gap: 8px;

            button {
                color: $primary-950;
                border: 1px solid $primary-950;

                &:hover {
                    background: #e6f7fe;
                }
            }
        }
    }

    .response-box {
        border-radius: 4px;
        word-break: break-all;
        max-height: 350px;
        overflow: auto;

        span {
            font-size: 14px;
            line-height: auto;
            width: 100%;
            font-weight: 500;
        }

        .json-output {
            padding: 0;
            margin: 0;
            overflow: unset;
            &:not(:last-child) {
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid #919191;
            }
        }
    }

    .emr-form-main {
        border-radius: 4px;

        label {
            font-weight: 500;
            color: #737373;
            font-size: 12px;
        }

        input {
            background-color: transparent;
        }
    }

    .input-with-button {
        display: flex;
        gap: 8px;

        button {
            padding: 10px;
            border-radius: 4px;
            color: #333;
            font-weight: 500;
            border: 1px solid #D9D9D9;
        }
    }
}