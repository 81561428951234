.inventory-order-detail-box {
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    padding: 16px 0;
    #create-order-tab {
      padding: 0 16px;
      .nav-link {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #8c8c8c;
        border: none;
        &:hover,
        &:focus {
          border-color: transparent;
        }
        &.active {
          font-weight: 600;
          color: #044d6e;
          border: none;
          border-bottom: 2px solid #044d6e;
        }
      }
    }
    .tab-content {
      padding: 0 16px;
      .inventory-tab-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
          margin: 0;
          font-size: 12px;
          line-height: 16px;
          color: #8c8c8c;
        }
        .inventory-tab-action-wrapper {
          display: flex;
          align-items: center;
          gap: 16px;
          .inventory-tab-action-box {
            font-size: 14px;
            line-height: 19px;
            font-weight: 600;
            color: #595959;
            position: relative;
            cursor: pointer;
            &:after {
              content: "";
              width: 1px;
              height: 16px;
              background-color: #f2f2f2;
              position: absolute;
              right: -8px;
              top: 3px;
            }
            &:last-child:after{
              content: none;
            }
          }
          .inventory-tab-action-dropdown {
            display: flex;
            gap: 5px;
            align-items: center;
            .dropdown-menu {
              min-width: 7rem;
              .dropdown-item {
                border-bottom: 1px solid #d9d9d9;
                padding: 8px 8px;
                font-size: 14px;
                line-height: 19px;
                color: #333333;
                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
          .inventory-tab-action-trans-report {
            svg {
              margin-right: 6px;
            }
          }
          .inventory-tab-more-action-wrapper {
              display: flex;
              gap: 12px;
              align-items: center;
          }
        }
      }
      .inventory-order-table-wrapper{
        margin-top: 24px;
        .listing-table-wrapper {
          border: 1px solid #f2f2f2;
          border-radius: 8px;
          max-width: 100%;
          overflow: auto;
        }
      }
    }
    .invt-create-order-content-wrapper {
      .invt-order-section-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.full-width {
          grid-column: span 3;
        }
        h5 {
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 0;
        }
        .inventory-actions-box-wrapper {
          display: flex;
          align-items: center;
          gap: 32px;
          .print-barcode-btn-box {
            display: flex;
            align-items: center;
            cursor: pointer;
            span.print-barcode-btn {
              font-size: 14px;
              display: inline-flex;
              align-items: center;
              gap: 5px;
              color: #333333;
              position: relative;
              &::after {
                content: "";
                width: 1px;
                height: 21px;
                background-color: #d9d9d9;
                position: absolute;
                right: -16px;
                top: 0px;
              }
            }
          }
          .inventory-actions-box {
            display: flex;
            gap: 12px;
            span.invt-action-btn {
              cursor: pointer;
              width: 24px;
              height: 24px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .listing-table-wrapper{
        margin-top: 24px;
        max-width: 100%;
        overflow-x: auto;
        border: 1px solid #f2f2f2;
        border-radius: 8px;
        table {
          margin-bottom: 0;
          td,
          th {
            white-space: nowrap;
            border: none;
          }
          th {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            padding: 14px 12px;
            text-transform: uppercase;
            .form-check-input[type=checkbox]{
              width: 15px;
              height: 15px;
            }
          }
          td {
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            padding: 14px 12px!important;
            vertical-align: middle;
            span.negative-value {
              color: #D34343;
            }
          }
          tbody {
            tr {
              &:last-child {
                td {
                  background-color: #fafbfc;
                }
              }
            }
          }
        }
      }

      .invt-overview-info-box {
        label {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #8c8c8c;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 19px;
          color: #333333;
          margin: 0;
        }
        &.full-width {
          grid-column: span 3;
        }
      }
    }
}
.inventory-order-header{
  margin-bottom: 24px;
  h2 {
    font-size: 28px;
    line-height: 38px;
    font-weight: 600;
    color: #044D6E;
  }
}
.order-filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .order-filter-item-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    .filter-item-box {
      position: relative;
      min-width: 120px;
      max-width: 130px;
      z-index: 2;
      label {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: #737373;
      }
      input {
        &.form-control {
          min-width: 120px;
          max-width: 120px;
        }
      }
      select {
        &.form-select {
          min-width: 120px;
          max-width: 120px;
        }
      }
      &.filter-checkbox-items {
        padding-top: 35px;
      }
      &.clear-all-box {
        padding-top: 25px;
        margin-left: 15px;
        &:after{
          content: '';
          width: 1px;
          height: 20px;
          background-color: #D9D9D9;
          position: absolute;
          left: -10px;
          top: 30px;
        }
        .clear-filter-btn {
          border: none;
          background: none;
          text-decoration: underline;
          font-size: 14px;
          line-height: 19px;
          color: #333333;
        }
      }
      .react-calendar {
        right: initial;
      }
    }
  }
  .order-action-box {
    padding-top: 25px;
    display: flex;
    align-items: center;
    gap: 24px;
    .order-action-btn {
      position: relative;
      border: none;
      background: none;
      font-size: 14px;
      line-height: 19px;
      color: #333333;
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      &:after{
        content: '';
        width: 1px;
        height: 20px;
        background-color: #D9D9D9;
        position: absolute;
        right: -10px;
        top: 3px;
      }
      &:last-child{
        &:after{
          content: none;
        }
      }
    }
  }
}
.create-order-action-container {
  display: flex;
  align-items: center;
  gap: 16px;
  .create-order-action-box{
    position: relative;
    &.clear-all-box{
      padding-top: 20px;
      cursor: pointer;
      font-size: 12px;
      margin-left: 10px;
      border-bottom: 1px solid #333333;
      &:after{
        content: '';
        width: 1px;
        height: 20px;
        background-color: #D9D9D9;
        position: absolute;
        left: -12px;
        top: 22px;
      }
    }
     label{
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
    }
    .vendorselectbox{
      width: 200px;
      .css-1nmdiq5-menu{
        width: 200px;
        z-index: 2;
      }
    }
    button {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 9px 10px;
    }
  }
}
#trans-report-modal{
  .modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 95%;
      h4{
        font-size: 16px;
        line-height: 21px;
        font-weight: 600;
        color: #333333;
        margin: 0;
      }
      .trans-report-action-wrapper {
          display: flex;
          align-items: center;
          gap: 24px;
          .trans-report-action-box{
            position: relative;
            font-size: 14px;
            line-height: 19px;
            font-weight: 500;
            cursor: pointer;
            &.export-report-opt-box{
              display: flex;
              align-items: center;
              gap: 5px;
            }
            &:after {
                content: "";
                width: 1px;
                height: 21px;
                background-color: #f2f2f2;
                position: absolute;
                right: -12px;
                top: 3px;
            }
            &:last-child:after{
              content: none;
            }
            .dropdown-menu{
              &.show{
                top: 14px !important;
                min-width: 90px;
                border-color: #F2F2F2;
                box-shadow: 0px 4px 4px 0px #0000000A;
                border-radius: 4px;
              }
              .dropdown-item{
                font-size: 14px;
                line-height: 19px;
                color: #333333;
                border-bottom: 1px solid #F2F2F2;
                padding: 8px;
                img{
                  width: 14px;
                  height: 14px;
                  margin-right: 8px;
                }
                &:last-child{
                  border-bottom: none;
                }
              }
            }
          } 
      }
  }
  .modal-body{
    background-color: #F5F5F5;
    padding: 0;
    .trans-report-content-wrapper {
      background-color: #ffffff;
      padding: 24px 0;
      border: 1px solid #F2F2F2;
      border-radius: 8px;
      max-width: 90%;
      margin: 40px auto;
      .trans-report-content-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 24px;
        border-bottom: 1px solid #F2F2F2;
        *{
          margin: 0;
          padding: 0;
        }
        .header-content{
          h5{
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;
            color: #333333;
          }
          p{
            font-size: 14px;
            line-height: 19px;
            font-weight: 500;
            color: #8C8C8C;
          }
        }
        .header-logo-wrapper{
          display: flex;
          align-items: center;
          gap: 20px;
          .header-logo-box{
            position: relative;
            &.sf-logo-box{
              img{
                width: 140px;
              }
            }
            &:after {
                content: "";
                width: 1px;
                height: 26px;
                background-color: #d9d9d9;
                position: absolute;
                right: -10px;
                top: 3px;
            }
            &:last-child:after{
              content: none;
            }
          }
        }
      }
      .trans-report-detail-box-wrapper{
        padding: 16px 24px;
        h5{
          font-size: 20px;
          font-weight: 600;
          line-height: 27px;
          color: #333333;
        }
        .trans-report-detail-box-container {
            display: flex;
            align-items: center;
            gap: 48px;
            .trans-report-detail-box{
              position: relative;
              label{
                font-size: 14px;
                line-height: 19px;
                font-weight: 500;
                color: #8C8C8C;
              }
              p{
                font-size: 16px;
                line-height: 21px;
                font-weight: 500;
                color: #333333;
                margin: 0;
              }
              &:after{
                content: "";
                width: 1px;
                height: 36px;
                background-color: #f2f2f2;
                position: absolute;
                right: -24px;
                top: 8px;
              }
              &:last-child:after{
                content: none;
              }
            }
        }
        .trans-report-table-wrapper{
          margin-top: 16px;
          border: 1px solid #F2F2F2;
          border-radius: 8px;
          &>div{
            height: 100dvh !important;
          }
          table{
            margin-bottom: 0;
            td,th {
              // white-space: nowrap;
              border: none;
            }
            th{
                background-color: #FAFBFC;
                font-size: 12px;
                font-weight: 600;
                color: #8C8C8C;
                line-height: 16px;
                padding: 14px 12px;
                text-transform: uppercase;
            }
            tbody{
                th{
                    color: #333333;
                }
            }
            td{
                font-size: 14px;
                font-weight: 400;
                color: #8C8C8C;
                line-height: 19px;
                padding: 12px 12px;
                vertical-align: middle;
            }
          }
        }
      }
  }
  }
}